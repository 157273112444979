.why-hoste.restaurant-platform {
  .benefits-title {
    font-size: 60px;
    font-weight: 900;
    text-align: center;
    margin-top: 50px;
  }
  .restaurant-platform-hero.demo__software .element_ui img {
    width: 100%;
  }
}
