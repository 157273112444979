.guest-app {
  margin-bottom: 100px;

  .right-image-hero {
    padding-bottom: 0px;
  }

  .guest-app-title {
    font-size: 60px;
    font-weight: 900;
    text-align: center;
    margin-top: 50px;
  }

  .text-highlight {
    font-weight: bold;
  }
}
