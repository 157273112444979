.team-founders {
  padding: 150px 0px;
  margin-top: 50px;

  &.gb_team_te div:nth-child(2) {
    margin-top: -150px;
  }

  &.gb_team_te div:nth-child(3) {
    margin-top: -100px;
  }

  &.gb_team_te div:nth-child(6) {
    margin-top: -100px;
    margin-left: 200px;
    visibility: hidden;
  }

  &.gb_team_te div:nth-child(4) {
    margin-top: 75px;
  }

  &.gb_team_te .hg_person .txt {
    margin-top: 1.25rem;
  }

  .hg_person {
    .avatar🐱 {
      &:before {
        content: "";
        width: 100%;
        height: 80.5%;
        position: absolute;
        border-radius: 8px;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(41, 1, 62, 0);
        -webkit-transition: 0.3s !important;
        -moz-transition: 0.3s !important;
        -o-transition: 0.3s !important;
        -ms-transition: 0.3s !important;
        transition: 0.3s !important;

        @media (max-width: 768px) {
          width: 77%;
          margin: 0px auto;
        }

        @media (max-width: 425px) {
          width: 65%;
        }

        @media (max-width: 375px) {
          width: 74%;
        }

        @media (max-width: 375px) {
          width: 74%;
        }

        @media (max-width: 320px) {
          width: 88%;
        }
      }

      .social {
        position: absolute;
        width: 75px;
        height: 75px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        top: 29%;
        left: 34%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        -webkit-transition: 0.3s !important;
        -moz-transition: 0.3s !important;
        -o-transition: 0.3s !important;
        -ms-transition: 0.3s !important;
        transition: 0.3s !important;
        -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1)
          both !important;
        -moz-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1)
          both !important;
        -o-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both !important;
        -ms-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1)
          both !important;
        animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both !important;

        @media (max-width: 768px) {
          left: 42%;
          top: 32%;
        }

        .tio {
          font-size: 40px;
          color: #ffffff;
        }
      }
    }

    &:hover .avatar🐱:before {
      background: rgba(245, 70, 78, 0.7);
    }

    .no-profile.avatar🐱:before {
      background: rgba(0, 0, 0, 0);
    }

    &:hover .avatar🐱 .social {
      -webkit-animation: puff-in-center 0.35s
        cubic-bezier(0.47, 0, 0.745, 0.715) both !important;
      -moz-animation: puff-in-center 0.35s cubic-bezier(0.47, 0, 0.745, 0.715)
        both !important;
      -o-animation: puff-in-center 0.35s cubic-bezier(0.47, 0, 0.745, 0.715)
        both !important;
      -ms-animation: puff-in-center 0.35s cubic-bezier(0.47, 0, 0.745, 0.715)
        both !important;
      animation: puff-in-center 0.35s cubic-bezier(0.47, 0, 0.745, 0.715) both !important;
      opacity: 1;
    }
  }

  @media (max-width: 991px) {
    padding: 0px;

    &.gb_team_te div:nth-child(5) {
      margin-top: 0px !important;
    }

    &.gb_team_te div:nth-child(2),
    &.gb_team_te div:nth-child(3),
    &.gb_team_te div:nth-child(4),
    &.gb_team_te div:nth-child(6) {
      margin: 0px;
    }
  }

  @media (max-width: 768px) {
    .hg_person {
      text-align: center;

      img {
        width: 255px;
      }

      .txt {
        text-align: center;
      }
    }

    .founder-img-blank {
      text-align: center;

      img {
        width: 60%;
      }
    }
  }
}

.team_section .switch_team .content_team {
  margin-top: 0px;

  .team-member-wrapper {
    padding: 100px 0px;

    @media (max-width: 768px) {
      & {
        padding: 50px 0px;
      }
    }
  }
}

.team-members {
  margin: 0px 50px;
  padding-bottom: 0px;

  @media (max-width: 768px) {
    & {
      margin: 0px 10px;
    }
  }

  &.team_section .switch_team .content_team .itemperson .av_person {
    display: block;
    margin: 0px !important;
  }

  &.team_section
    .switch_team
    .content_team
    .itemperson
    .row
    .team-member-wrapper:nth-child(n)
    .av_person {
    margin: 0px !important;
    position: relative;
    top: -75px;

    @media (max-width: 768px) {
      & {
        top: 0px;
      }
    }
  }

  &.team_section
    .switch_team
    .content_team
    .itemperson
    .row
    .team-member-wrapper:nth-child(2n)
    .av_person {
    margin-left: 0px !important;
    position: relative;
    top: 75px;

    @media (max-width: 768px) {
      & {
        top: 0px;
      }
    }
  }

  &.team_section
    .switch_team
    .content_team
    .itemperson
    .team-head-shots
    .av_person
    .info_name
    p {
    color: #778491;
    font-size: 14px;
  }

  &.team_section
    .switch_team
    .content_team
    .itemperson
    .av_person:hover
    .avatar🐱:before {
    background: rgba(245, 70, 78, 0.7) !important;
  }

  &.team_section
    .switch_team
    .content_team
    .itemperson
    .team-head-shots
    .av_person:hover
    .no-profile.avatar🐱:before {
    background: rgba(0, 0, 0, 0);
  }

  @media (max-width: 991px) {
    &.team_section
      .switch_team
      .content_team
      .itemperson
      .team-head-shots
      .av_person {
      margin: auto !important;
      margin-bottom: 1.25rem !important;
    }
  }

  .particl_colors {
    span:nth-child(1) {
      left: 17%;
      margin-top: -75px;
    }

    span:nth-child(2) {
      margin-top: 0px;
      left: 43%;
    }

    span:nth-child(3) {
      left: 0px;
      margin-top: 30%;
    }

    span:nth-child(4) {
      margin-top: 6%;
      margin-right: 100px;
    }

    span:nth-child(5) {
      margin-top: 100px;
      margin-right: 80px;
    }

    span:nth-child(6) {
      background-color: rgba(79, 254, 248, 0.17);
      margin-right: 28%;
      top: -100px;
    }

    span:nth-child(7) {
      margin-top: 12%;
      left: 17%;
    }

    span:nth-child(8) {
      background-color: rgba(248, 178, 107, 0.4);
      left: 52%;
      margin-top: 22%;
    }

    span:nth-child(9) {
      background-color: rgba(253, 79, 254, 0.3);
      left: 81%;
      margin-top: 20%;
    }

    span:nth-child(10) {
      background-color: rgba(253, 79, 254, 0.3);
      left: 28%;
      margin-top: 17%;
    }

    @media (max-width: 991px) {
      span:nth-child(1) {
        margin-top: -75px;
      }

      span:nth-child(2) {
        margin-top: 0px;
      }

      span:nth-child(3) {
        margin-top: 70%;
      }

      span:nth-child(4) {
        margin-top: 30%;
      }

      span:nth-child(5) {
        margin-top: 30%;
      }

      span:nth-child(6) {
        top: -100px;
      }

      span:nth-child(7) {
        margin-top: 60%;
      }

      span:nth-child(8) {
        margin-top: 70%;
      }

      span:nth-child(9) {
        margin-top: 90%;
      }

      span:nth-child(10) {
        margin-top: 17%;
      }
    }
  }
}
