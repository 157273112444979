.content-slider {
  background-image: url(../../../../Assets/content-slider-background-red.png);
  background-size: 90% 425px;

  .social-media-post {
    .social-content {
      padding: 10px;

      p {
        font-size: 20px;
        font-weight: 600;
        margin: 0px;
      }

      .map-slider-content {
        .description {
          font-size: 25px;
        }

        .map-location-content {
          display: flex;
          justify-content: space-between;

          .category-location-content {
            display: flex;
            flex-direction: column;

            .location-category {
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 10px;
            }

            .location-pin {
              display: flex;
              align-items: center;

              .pin-img {
                height: 47px;
              }

              .location {
                font-weight: 400;
                font-size: 16px;
                margin-left: 10px;
              }
            }
          }

          .map-content {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .map-img {
              height: 94px;
            }
          }
        }
      }

      .quote-slider-content {
        padding: 10px;

        .quote {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 15px;
        }

        .quote-author {
          font-size: 16px;
        }

        .author-title {
          font-size: 12px;
          font-weight: 400;
          color: #8991a3;
        }

        .custom-button.btn_video {
          background-color: #1b98ed;
        }
      }

      .quote-img {
        position: absolute;
        top: 10px;
        width: 50px;
        left: -40px;
      }
    }
  }

  .social-video-button {
    text-align: center;
    margin-left: 40px;
    margin-top: 25px;

    .custom-button {
      background-color: #1b98ed;
      margin-right: 0px;
    }
  }

  .slider-average .swiper-slide {
    width: 375px !important;
    margin-right: 30px;
  }

  .slider-average .social-media-post {
    margin-left: 40px;
  }

  .slider-articles {
    .swiper-slide {
      width: 375px !important;
      margin-right: 30px;

      .article-title {
        font-size: 21px;
        line-height: normal;
      }
      .article-subtitle {
        font-size: 17px;
        font-weight: 400;
        font-style: italic;
        line-height: normal;
        margin: 10px 0px;
      }
      .article-link-content {
        display: flex;
        align-items: flex-end;
        //background-color: pink;
        margin: 20px 0px 5px 0px;

        p {
          color: #1b98ed;
          font-weight: 400;
          font-size: 17px;
          flex-grow: 1;
        }
        .article-links {
          a {
            margin: 0px 5px;
            img {
            }
          }
        }
      }
    }
  }

  .light-arrow {
    border: 1px solid black;

    .tio {
      color: #000 !important;
    }
  }
}
