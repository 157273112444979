.sign-up-modal {
  .modal-dialog {
    max-width: 800px;

    .modal-content {
      padding: 15px;

      .modal-header {
        padding-bottom: 0px;

        .modal-title {
          font-size: 30px;
          font-weight: 700;
        }

        .btn-close {
          box-sizing: content-box;
          width: 12px;
          height: 12px;
          padding: 14px;
          border: 0;
          border-radius: 25px;
          opacity: 1;
          background: transparent url(../../../../Assets/Icons/x-icon.svg) 50%/1em
            auto no-repeat;
          background-size: 12px;
          background-color: #f5f4f6;
          position: absolute;
          right: 30px;
          top: 30px;
        }
      }

      .modal-body {
        padding-top: 10px;

        .form-description {
          font-size: 16px;
          line-height: 24px;
          color: #8991a3;
          margin-bottom: 30px;

          &:first-of-type {
            margin-bottom: 15px;
          }

          span {
            color: #007aff;
            cursor: pointer;
          }
        }

        .form-label {
          font-size: 14px;
        }

        input {
          padding: 25px 15px;
          border-radius: 10px;
          font-size: 14px;
          color: #0b2238;
        }

        .form-select {
          padding: 18px 25px 18px 15px;
          border-radius: 10px;
          color: #0b2238;
          border-color: #ced4da;
          font-size: 14px;
          width: 100%;
          background-image: url(../../../../Assets/Icons/down-icon.svg);
          background-repeat: no-repeat;
          background-position: right 0.75rem center;
          background-size: 11px 10px;
          -webkit-appearance: none;
          appearance: none;

          &:focus-visible {
            border-color: #ced4da;
            border-radius: 10px;
          }

          .default-select {
            color: #b7bbc4;
          }
        }

        ::placeholder {
          color: #b7bbc4;
        }

        .form-disclaimer {
          font-size: 12px;
          color: #8991a3;
        }

        .restaurant-submit {
          font-size: 16px;
          width: 100%;
          border-radius: 50px;
          background-color: #007bff;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
}

.sign-up-error-success-modal.modal {
  background-color: rgba(0, 0, 0, 0.3);
}
