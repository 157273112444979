.restaurant-platform {
  padding-bottom: 100px;

  .restaurant-platform-hero {
    @media (max-width: 1199px) {
      padding-bottom: 0px;
      height: 700px;
    }

    &.demo__software::before {
      background-image: url(../../../Assets/why-hoste-hero-background.svg);
      height: 765px;
      background-size: initial;

      @media (max-width: 991px) {
        height: 500px;
      }
    }

    &.demo__software .element_ui::before {
      background-image: none;
      width: 672px;
      background-repeat: no-repeat;
      bottom: -30%;

      @media (max-width: 991px) {
        display: none;
      }
    }

    .banner_title {
      .offer span:last-child {
        color: #5b6782;
      }

      h1,
      p {
        color: #000;
      }

      p {
        font-size: 20px;
      }

      a.btn {
        padding: 20px 2.3rem;
        border-radius: 40px;
      }
    }

    &.demo__software .element_ui img {
      border-radius: 0px;
      width: 140%;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}
