.reviews-section.reviews_app_random {
  margin-top: 170px;
}

@media (max-width: 1505px) {
  .reviews-section .title_sections h2 {
    width: 600px;
  }
}

@media (max-width: 1140px) {
  .reviews-section .title_sections h2 {
    font-size: 50px !important;
    width: 500px;
  }
}

@media (max-width: 1087px) {
  .reviews_app_random .item_review.review_one {
    max-width: 35%;
  }
}

@media (max-width: 990px) {
  .reviews_app_random .item_review.review_two {
    top: 20%;
  }

  .reviews_app_random .item_review.review_four {
    top: 60%;
  }

  .reviews_app_random .item_review.review_three {
    max-width: 30%;
  }
}

@media (max-width: 768px) {
  .reviews-section .title_sections h2 {
    font-size: 50px !important;
  }

  .reviews_app_random .item_review.review_one {
    max-width: initial;
  }

  .reviews_app_random .item_review.review_three {
    max-width: initial;
  }

  .reviews-section.reviews_app_random {
    padding-bottom: 50px;
  }
}
