.help-center {
  .help-center-hero {
    background-color: #fbedd3;
    margin: 100px 25px 25px 25px;
    border-radius: 5px;
    padding: 75px;

    @media (max-width: 425px) {
      padding: 25px;
    }

    .hero-text {
      padding-right: 50px;
      h1 {
        font-weight: bold;
        font-size: 48px;
      }

      p {
        font-size: 15px;
        margin-bottom: 35px;
      }
    }

    .hero-image img {
      width: 100%;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .eate-faq.faq_section {
    background-color: #fff;
    padding-top: 50px;

    h2 {
      text-align: center;
      margin-bottom: 50px;
    }

    .faq-content {
      display: flex;
      justify-content: space-evenly;

      .section__showcase {
        width: 100%;

        .block__tab .nav-pills {
          justify-content: space-evenly;

          .nav-item {
            width: initial;
          }
        }
      }
    }
  }
}
