.icon-boxes-image-section .icon-box-supporting-img {
  position: relative;
  top: -275px;
  width: 850px;

  @media (max-width: 991px) {
    display: none;
  }
}

.iconBox .icon--top img {
  width: 24px;
}

@media (max-width: 768px) {
  .icon-boxes-image-section .title_sections h2 {
    font-size: 50px !important;
  }

  .icon-box-row {
    margin: 10px;
  }
}
