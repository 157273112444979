.hero-section {
  &.banner_app_two {
    height: initial;
  }
  .container {
    position: relative;
  }

  .hero-img img {
    height: 550px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;

    @media (max-width: 991px) {
      display: none;
    }
  }

  p {
    font-family: "CircularStd";
    font-size: 20px;
  }
}
