.footer-minimal {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;

  .eate-footer-top {
    display: none;
  }

  .eate-footer-bottom {
    background-color: transparent;
    padding: 0px 0px 25px 0px !important;

    .row:nth-of-type(1),
    .dividar {
      display: none;
    }

    .nav-link,
    .nav-link:hover {
      color: #6c7a87 !important;
    }
  }
}

.eate-footer-top {
  margin-bottom: -117px;
  z-index: 0;
  background-color: rgba(0, 123, 255, 0.03);

  .title_sections {
    margin-bottom: 40px;

    h2 {
      font-family: "CircularStd", sans-serif;
      font-weight: 900;
      font-size: 60px;
    }

    .eate-app-available-on {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .eate-available-details {
        &:first-of-type {
          margin-right: 20px;
        }

        &.available-active {
          p {
            color: #000;
          }
        }
      }
    }

    p {
      margin: 0px;
    }

    button {
      margin-top: 40px;
    }
  }

  @media (max-width: 991px) {
    .footer-side-imgs {
      display: none !important;
    }

    .footer-main-img {
      margin: 0px auto;
    }
  }

  @media (max-width: 768px) {
    .app_mobile:before {
      width: 700px;
      height: 700px;
      left: -15%;
      top: 20%;
    }
  }
}

.eate-footer-bottom {
  position: relative;
  z-index: 1;

  .eate-contact-form {
    h3 {
      color: #fff;
      font-size: 30px;
      font-family: "CircularStd", sans-serif;
    }

    p {
      color: #fff;
    }

    input {
      font-size: 13px;
    }

    button {
      position: absolute;
      top: 10px;
      right: 17px;
      color: #fff;
    }

    .form-group {
      margin: 0px;
    }
  }

  .copyright {
    .nav-link {
      display: inline-block;
      padding: 0px;
    }
  }

  @media (max-width: 991px) {
    .btn-social {
      margin-top: 20px;
    }

    .dividar {
      margin: 10px 0px 30px 0px;
    }
  }
}
