.image-tab-section {
  .highlighted-header {
    font-family: "CircularStd", sans-serif;
    font-weight: 700;
    font-size: 60px;
  }

  .txt_feat {
    span {
      display: inline-block !important;
      margin-bottom: 0px !important;
      width: 100%;
    }

    .image-tab-label {
      color: #f88161;
      background-color: rgba(248, 129, 97, 0.1);
      font-size: 11px !important;
      padding: 7px 10px;
      border-radius: 20px;
      margin-left: 10px;
      white-space: nowrap;
      display: inline-block;
      position: relative;
      top: -2px;
    }

    h3 {
      margin-top: 10px;
    }
  }
}

.mobile-view-phone {
  display: none;
}

@media (max-width: 768px) {
  .image-tab-section {
    padding-top: 100px !important;

    .image-section {
      display: none;
    }

    .mobile-view-phone {
      display: initial;
      width: 150px !important;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .nav-link {
      opacity: 1 !important;
      cursor: unset;

      .txt_feat span:first-of-type {
        color: #f5464e;
        font-size: 25px;
        display: block !important;
      }
    }
  }
}
