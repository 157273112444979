.hoste-video-library {
  margin-top: 100px;
}

.hoste-faq {
  background-color: white;

  &.hoste-byod-faq {
    background-color: rgba(248, 129, 97, 0.03);

    p:nth-of-type(2) {
      margin-bottom: 7px;
    }

    ul {
      list-style: none;

      li {
        margin-bottom: 7px;

        &:before {
          content: "\2022";
          color: #f5464e;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }

  &.faq_section {
    padding: 100px 0px;
    margin: 0px;

    .title_sections_inner {
      p {
        font-size: 16px;
        color: #0b2238;
      }
    }
  }
}
