.restaurant-consulting {
  margin-top: 100px;

  .text-hero {
    h1 {
      font-size: 60px;
    }

    p {
      padding: 0px 25%;
    }
  }

  .consult-stats-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 0px;

    .consult-stat {
      border: 1px solid #8f9aaa;
      border-radius: 7px;
      text-align: center;
      margin: 0px 10px;
      padding: 5px 25px 10px 25px;

      p {
        margin: 0px;

        &:nth-child(1) {
          font-weight: bold;
          font-size: 38px;
          margin-bottom: 10px;
        }

        &:nth-child(2) {
          color: #8991a3;
          font-size: 12px;
        }
      }
    }
  }

  .consult-img {
    display: flex;
    justify-content: center;
    height: 223px;
  }

  .consult-services-container {
    display: flex;
    flex-direction: column;
    margin-top: 100px;

    .services-header {
      display: flex;
      align-items: center;
      flex-direction: column;

      h2 {
        font-weight: bold;
      }
    }

    .consult-services {
      display: flex;
      justify-content: space-evenly;
      margin: 25px 0px;

      @media (max-width: 567px) {
        flex-direction: column;
      }

      .consult-service {
        text-align: center;
        width: 25%;

        @media (max-width: 768px) {
          width: 45%;
        }

        @media (max-width: 567px) {
          width: 60%;
          margin-left: auto;
          margin-right: auto;
        }

        &:nth-child(2) {
          @media (max-width: 567px) {
            margin-top: 50px;
          }
        }

        h3 {
          margin: 20px 0px;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: bold;
        }

        p {
          margin-bottom: 15px;
          border: 1px solid #f5464e;
          color: #f5464e;
          padding: 7px 0px 5px 0px;
          border-radius: 10px;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }

  .consult-connect {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7fbff;
    padding: 50px 0px;

    h2 {
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 20px;
    }

    a {
      background-color: #007aff;
      color: #fff;
      padding: 7px 25px;
      border-radius: 20px;
    }
  }

  .consult-clients-container {
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0px;

    @media (max-width: 768px) {
      margin: 50px 25px;
      flex-direction: column;
      align-items: center;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 25px;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .consult-client {
      @media (max-width: 425px) {
        width: 100%;
      }

      .client-info {
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
        border-radius: 10px;

        @media (max-width: 768px) {
          width: 370px;
          margin-top: 30px;
        }

        @media (max-width: 425px) {
          width: 100%;
        }

        img {
          width: 100px;
          border-radius: 10px 0px 0px 10px;
        }

        p {
          margin: 0px;
          font-weight: bold;
          padding: 0px 15px;
          font-size: 18px;
        }
      }
    }

    .consult-client-quote {
      width: 370px;
      border: 1px solid #8f9aaa;
      box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      padding: 20px;
      margin-left: 40px;
      position: relative;

      @media (max-width: 768px) {
        margin-top: 40px;
        margin-left: 0px;
      }

      @media (max-width: 425px) {
        width: initial;
        margin-left: 20px;
      }

      .client-quote {
        p {
          margin: 0px;

          &:nth-child(2) {
            margin-bottom: 15px;
          }

          &:nth-child(4) {
            color: #8991a3;
            font-size: 12px;
          }
        }

        img {
          position: absolute;
          top: 5px;
          left: -40px;
          width: 50px;
        }
      }
    }
  }
}
