.restaurant-platform {
  .demo__software {
    height: initial;
    padding-bottom: 50px;
  }

  .toggle-slider-section {
    margin-top: 50px;

    .colored-square-phone-section {
      &:first-of-type {
        padding-top: 0px !important;
      }
    }

    .text-highlight {
      font-weight: bold;
    }
  }
}
