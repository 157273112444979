.icon-box-section {
  .icon-boxes {
    margin-bottom: 75px;

    .icon--top {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 12px;

      img {
        width: 30px;
      }
    }
  }
}
