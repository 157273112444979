.company-culture-values {
  padding: 100px 180px;

  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 25px;
  }

  p {
    font-size: 20px;
  }
}
