.why-eate {
  padding-bottom: 100px;

  .why-eate-header {
    .multi_image {
      margin: 0px;

      img {
        border-radius: 10px;
      }

      @media (max-width: 768px) {
        justify-content: center;

        :nth-child(3) {
          margin-right: 0px;
        }

        .group_img {
          display: none;
        }
      }
    }
  }
}
