.eate-faq {
  background-color: rgba(248, 129, 97, 0.03);

  &.faq_section {
    padding: 100px 0px;
  }
}

.eate-video-library {
  padding: 100px 0px;

  h2 {
    font-weight: bold;
  }
}
