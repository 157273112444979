.platform-details-container {
  padding-top: 200px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 3.125rem;
    font-weight: 700;
  }

  .platform-details-content {
    display: flex;
    justify-content: space-evenly;

    h3 {
      text-align: center;
    }

    img {
      width: 100%;
    }

    .section__showcase {
      width: 100%;
      padding-bottom: 0px;

      .block__tab .nav-pills {
        justify-content: space-evenly;

        .nav-item {
          width: initial;
        }
      }
    }
  }
}

.platform-results-container {
  text-align: center;
  margin-top: 125px;

  h2 {
    font-size: 40px;
    margin: 0px 0px 25px 0px;
  }

  .platform-results-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .platform-result {
      border: 1px solid #8f9aaa;
      border-radius: 7px;
      padding: 5px 25px 10px 25px;
      margin: 25px 10px 0px 10px;

      p {
        margin: 0px;

        &:nth-of-type(1) {
          color: #f5464e;
          font-weight: bold;
          font-size: 40px;
        }

        &:nth-of-type(2) {
          color: #8991a3;
          font-size: 17px;
        }
      }
    }
  }

  .results-disclaimer {
    font-size: 15px;
    font-style: italic;
    margin-top: 15px;
  }
}

.restaurant-quotes,
.eate-articles {
  margin-top: 100px;
}
