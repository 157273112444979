.right-image-hero {
  padding-bottom: 100px;

  &.grayGradient {
    background-image: linear-gradient(white 60%, #f5f5f5);
  }

  .before_title {
    span {
      &:nth-of-type(2) {
        margin-left: 5px;
      }

      &:last-child {
        color: #f5464e;
      }
    }
  }

  img {
    border-radius: 10px;
  }

  .right-image-hero-header-large {
    font-weight: 900;
    font-size: 80px;
  }
}
