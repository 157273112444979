.icon-tabs {
  padding: 0px 0px 100px 0px;

  .nav-pills {
    .active {
      .icon {
        background-color: rgba(0, 123, 255, 0.1) !important;
      }

      .prog {
        background-color: rgba(0, 123, 255, 1) !important;
      }

      .icon-tab-active-icon {
        display: block;
      }

      .icon-tab-inactive-icon {
        display: none;
      }
    }

    img {
      width: 24px;
    }

    .icon-tab-active-icon {
      display: none;
    }

    .icon-tab-inactive-icon {
      display: block;
    }

    .nav-item {
      position: relative;
    }

    .disabled-icon-tab.nav-item a {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }

    .label-icon-tab {
      background-color: rgba(248, 129, 97, 0.1);
      color: #f88161;
      border-radius: 20px;
      font-size: 11px;
      padding: 5px 10px;
      position: absolute;
      left: 75px;
      top: 15px;
    }
  }
}

.section__showcase .block__tab .nav-pills .nav-item {
  width: calc(100% / 5);

  @media (max-width: 991px) {
    width: calc(100% / 3);
  }

  @media (max-width: 768px) {
    width: calc(100% / 2);
  }
}
