.coming-soon-android {
  background-color: #f4fbff;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 145px;

  @media (min-width: 358px) {
    padding-bottom: 115px;
  }

  @media (min-width: 616px) {
    padding-bottom: 78px;
  }

  @media (min-width: 768px) {
    padding-bottom: 62px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 57px;
  }

  .android-content {
    text-align: center;

    h1 {
      color: #f5464e;
      font-size: 120px;
      font-weight: bold;
      margin: 0px 0px 50px 0px;

      @media (max-width: 492px) {
        font-size: 80px;
        margin: 50px 0px;
      }

      @media (max-width: 387px) {
        font-size: 60px;
        margin: 30px 0px;
      }

      @media (max-width: 374px) {
        margin: 20px 0px;
      }
    }

    h2 {
      margin-bottom: 40px;

      @media (max-width: 374px) {
        margin-bottom: 20px;
      }

      @media (max-width: 334px) {
        font-size: 25px;
      }
    }

    .custom-button {
      margin-right: 0px;
    }

    .android-nav {
      margin-top: 40px;

      @media (max-width: 374px) {
        margin-top: 20px;
      }

      a {
        color: #000;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.eate-footer {
  background-color: #f4fbff;
  padding: 10px 0px;

  .eate-footer-bottom {
    padding-bottom: 0px !important;
  }
}
