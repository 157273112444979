.text-hero {
  text-align: center;

  h1 {
    font-size: 50px;
    font-weight: 700;

    .text-styling-icon {
      margin-top: -10px;
      margin-right: 10px;
    }
  }

  p {
    color: #8991a3;
  }
}
