.colored-square-phone-section {
  .amo_pic.horizontal_amo_pic {
    img {
      width: initial;
      height: 450px;
      left: 90px;
      top: 25px;

      @media (max-width: 991px) {
        left: inherit;
      }

      @media (max-width: 768px) {
        height: 300px;
      }
    }

    &.inverted-image img {
      left: -180px;

      @media (max-width: 991px) {
        left: inherit;
      }
    }
  }

  .title_sections {
    p {
      font-size: 16px;
      line-height: 27px;
    }
  }
}
