.request-eate-demo {
  .text-hero {
    padding: 200px 0px 100px 0px;
  }

  .calendly-inline-widget {
    min-width: 320px;
    height: 750px;
  }

  .demo-description {
    padding: 0px 30%;
  }
}
