.custom-button {
  background-color: #f5464e;
  color: #fff;
  margin-right: 15px;

  &.btn:hover,
  :hover {
    color: #fff;
  }

  .custom-button-inverted {
    border: 2px solid #1b98ed;
    color: #1b98ed;
    background-color: transparent;
  }
}
