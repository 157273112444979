.signup_full {
  background-size: 100% 100vh;

  #root {
    height: 100vh;

    .item_brand a img {
      width: 90px;
    }

    .form_signup_one {
      padding: 13% 0px;
    }

    .item_footer {
      position: absolute;
      bottom: 15px;
      padding: 0px;
    }
  }
}
