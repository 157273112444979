.social-media-slider-row {
  padding-bottom: 100px;
}

.social-media-slider-accent {
  position: absolute;
  bottom: 0;
}

.social-media-slider {
  background: none;
  position: relative;
  background-image: url(../../../../Assets/social-media-slider-accent.svg);
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;

  .title_sections h2 {
    font-family: "CircularStd", sans-serif;
    color: #0b2238;
    font-size: 50px;
    font-weight: 600;
  }

  .body__swipe .swiper-button-next {
    background-color: rgba(245, 70, 78, 0.05);
    top: 170px;

    &:hover {
      background-color: #f5464e;
    }
  }
}

.social-media-post {
  background-color: #fff;
  box-shadow: 0px 0px 10px #868080;
  border-radius: 17px;
}

.swiper-slide {
  .social-media-post {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    position: relative;

    &:nth-child(1) {
      margin-top: 10px;
    }

    &:nth-child(2) {
      margin-top: 30px;
    }
  }
}

img.slider-img {
  width: 100%;
  margin-top: -1px;
}

.social-content {
  padding: 35px;

  .description {
    font-size: 18px;
  }

  .social-hashtag {
    color: #1b98ed;
  }
}

.social-footer {
  display: flex;
  align-items: flex-end;
}

.post-time {
  font-size: 10px;
  color: #1b98ed;
}

.post-origin {
  margin-left: auto;
}

.slider-twitter .description {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;

  &:before {
    content: "“";
    font-size: 80px;
    position: absolute;
    top: 0;
  }

  &:after {
    content: "“";
    font-size: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(180deg);
  }
}
