.toggle-slider-section {
  h2 {
    font-size: 60px;
    font-weight: 900;
  }

  .toggle_switch .disabled-switch {
    opacity: 0.5;
  }

  .toggle_switch .toggle .switch:before {
    margin: auto;
    top: 0.25px;
    left: 0.25px;
    margin: 4px;

    @media (max-width: 768px) {
      top: 0.5px;
    }

    @media (max-width: 576px) {
      top: 0.15px;
      left: 0px;
    }
  }

  .switch-label {
    position: absolute;
    color: #f88161;
    background-color: rgba(248, 129, 97, 0.1);
    font-size: 11px;
    padding: 7px 10px;
    border-radius: 20px;
    margin-left: 10px;
    margin-top: -4px;
    white-space: nowrap;
  }
}
