.library-item-wrapper {
  padding: 10px;
  text-align: center;
  display: block;
  cursor: pointer;

  img {
    width: 264px;
  }

  .library-item-content {
    position: relative;

    h3 {
      margin-top: 10px;
      font-size: 24px;
    }

    span.video-library-label {
      position: absolute;
      color: #f88161;
      background-color: rgba(248, 129, 97, 0.1);
      font-size: 11px;
      padding: 7px 10px;
      border-radius: 20px;
      margin-left: 10px;
      white-space: nowrap;
    }

    p {
      font-size: 16px;
      color: #8991a3;
    }
  }
}
