.header-nav-center {
  background-color: transparent !important;

  &.header-minimal {
    .navbar-toggler,
    .navbar-collapse {
      display: none !important;
    }
  }

  .navbar-collapse {
    background-color: white;
    border: 1px solid gray;
    border-top: 0px;
    border-radius: 0px 0px 10px 10px;
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: 991px) {
      background-color: transparent;
      border: none;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .info-banner {
    width: 100%;
    background-color: #f5464e;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px;
    color: #ffffff;
    a {
      color: #ffffff;
      text-decoration: underline;
    }
  }

  header .navbar .navbar-brand .logo {
    width: 90px !important;
  }

  @media (max-width: 991px) {
    .dropdown-menu {
      border-radius: 0px;
    }
    .dropdown-menu.single-drop.sm_dropdown.show {
      height: initial;
      opacity: 1;
      visibility: visible;
    }

    .dropdown-toggle[aria-expanded="true"] .tio.chevron_right {
      transform: rotate(90deg);
    }

    .dropdown-item + .dropdown-menu.sec-drop {
      padding-top: 0px;

      a {
        margin: 0px !important;
      }
    }
  }
}

